<script>
import GlobalVue from '../../helper/Global.vue'
import Gen from '../../helper/Gen'

export default {
  extends: GlobalVue,
  data() {
    return {
      data:{},
    }
  },
  computed: {},
  mounted() {
    this.$emit("ready", this)
  },
  methods: {
    open(params){
      global.Modal = this
      $(this.$el).modal()
      if(params) this.data = params
    },
  },
  watch: {}
}
</script>

<template>
<div class="modal fade" id="ModalDetailPurchase">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{web.lbl_product_detail}}</h4>
					<button class="close" data-dismiss="modal" aria-label="close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="pd-content">
						<h4>{{data.title}}</h4>
						<div class="pd-img">
							<img :src="uploader(data.image)" alt="Package Image">
						</div>
						<div v-html="data.description"></div>
					</div>
				</div>
				<div class="modal-footer">
					<a :href="'https://wa.me/'+data.subscribe_phone+'?text='+data.subscribe_message+''" target="_blank" class="button button-red">{{web.btn_purchase_now}}</a>
				</div>
			</div>
		</div>
	</div>
</template>